import request from '../utils/request'
import config from './config'

/**
 * 志愿者团队
 */

// 新增志愿者团队
export function addVolunteerGroup(data) {
	return request({
		url: `${config.CIVIL_HOST}/volunteerGroup/addVolunteerGroup`,
		method: 'post',
		data,
	})
}
// 返回部门和角色
export function backDepartmentAndRole(data) {
	return request({
		url: `${config.CIVIL_HOST}/volunteerGroup/backDepartmentAndRole`,
		method: 'post',
		data,
	})
}
// 返回区域列表
export function backRegionList(data) {
	return request({
		url: `${config.CIVIL_HOST}/volunteerGroup/backRegionList`,
		method: 'post',
		data,
	})
}
// 下载志愿者团队模板
export function downloadTemplate(data) {
	return request({
		url: `${config.CIVIL_HOST}/volunteerGroup/downloadVolunteerGroupTemplate`,
		method: 'post',
		data,
		responseType: 'blob',
	})
}
// 团队账号启用禁用
export function enableDisableVolunteerGroup(params) {
	return request({
		url: `${config.CIVIL_HOST}/volunteerGroup/enableDisableVolunteerGroup`,
		method: 'get',
		params,
	})
}
// 导出志愿者团队
export function exportVolunteerGroup(data) {
	return request({
		url: `${config.CIVIL_HOST}/volunteerGroup/exportVolunteerGroup`,
		method: 'post',
		data,
		responseType: 'blob',
	})
}
// 志愿团体ID
export function generateGroupId(data) {
	return request({
		url: `${config.CIVIL_HOST}/volunteerGroup/generateGroupId`,
		method: 'post',
		data,
	})
}
// 导入志愿者团队
export function importVolunteerGroup(data) {
	return request({
		url: `${config.CIVIL_HOST}/volunteerGroup/importVolunteerGroup`,
		method: 'post',
		data,
	})
}
// 返回志愿列表
export function selectVolunteerGroupList(data) {
	return request({
		url: `${config.CIVIL_HOST}/volunteerGroup/selectVolunteerGroupList`,
		method: 'post',
		data,
	})
}
// 更新志愿者团队信息
export function updateVolunteerGroup(data) {
	return request({
		url: `${config.CIVIL_HOST}/volunteerGroup/updateVolunteerGroup`,
		method: 'post',
		data,
	})
}

// 返回团队统计数据
export function backVolunteerStatisticData(data) {
	return request({
		url: `${config.CIVIL_HOST}/volunteerGroup/backVolunteerStatisticData`,
		method: 'post',
		data,
	})
}

// 返回当前单位所有部门
export function backDepartmentTreeNodeList(data) {
	return request({
		url: `${config.CIVIL_HOST}/volunteerGroup/backDepartmentTreeNodeList`,
		method: 'post',
		data,
	})
}